<template>
  <div class="relative flex flex-col w-full h-full text-gray-700 bg-white shadow-md rounded-lg bg-clip-border">
                <table class="w-full text-left table-auto">
                    <thead>
              <slot name="titulos_tabla">
                
              </slot>
          </thead>

          <tbody>
            <slot name="columna_tabla">
              
            </slot>
          </tbody>
      
      </table>
  </div>


</template>

<script setup>

</script>