<template>
    <div class="container">
          <h1 class="flex justify-center text-lg font-serif mt-2">ClaseCita
            <p class="text-lg  font-serif text-red-500 ml-1" 
             v-if="datosClaseCitas.length==1">{{ datosClaseCitas[0].clase_cita.nom_clase_cita }}</p></h1>
            
             <div v-if="datosClaseCitas.length !==1" class="flex justify-center">
                    <div class="ml-2" v-for="ClaseCitas in datosClaseCitas" :key="ClaseCitas.id_reg_agenda">
                        <BotonPrin :nombre="ClaseCitas.clase_cita.nom_clase_cita" @click="IdCita(ClaseCitas.id_reg_agenda)" />
                    </div>
                    
             </div>
             <div v-if="botonCita == true">
                <h1 class="flex justify-center text-lg font-serif mt-2"> Apartar-Cita</h1>
                 <div class="" >   
                     <ComAgendaCita />
                 </div>
             </div>
            
           

    </div>
  </template>
  

<script setup>
import { ref,watch} from 'vue';
import ServicioClaseCita from '@/servicios/ServicioClaseCita';
import { Citas } from '@/store/StoreCitas';
import BotonPrin from '@/components/botones/BotonPrin.vue';
import ComAgendaCita from './ComAgendaCita.vue';



    const store= Citas(); 
    const ServClaseCita = new ServicioClaseCita();
    const datosClaseCitas = ref([]);
    const botonCita=ref(false);

    watch(() => store.idClaseCita, async (NewIdClaseCita) => {
    if (NewIdClaseCita) {
        await ServClaseCita.CargaClaseCitas();
        datosClaseCitas.value = ServClaseCita.GetClaseCitas().value;
    }
    
    if(datosClaseCitas.value.length==1){
        store.StoreCita(datosClaseCitas.value[0].id_reg_agenda);
        botonCita.value=true;
    }

});

function IdCita(IdCita){
    this.store.StoreCita(IdCita)
    this.store.ShowAgenda(false)
    this.botonCita=true;
  }




 </script>
