
import { ref } from 'vue';
import { useAuthStore } from '@/store/auth';


export default class ViaAdmon {
    constructor() {

        this. store = useAuthStore();
        this. via_Admon = ref('');
      }

  GetViaAdmon() {
    return this.via_Admon;
  }

  async CargaViaAdmon() {
    try {
      const url =this.store.baseurl+'servicios/viadmon';
      const resultado = await fetch(url, {
        method: 'GET',
        headers: {
           'Content-Type':'application/json',
            'Accept':'application/json',
            'Authorization': 'Bearer '+this.store.token
        }
      });
      const datos = await resultado.json();
      this.via_Admon.value = datos;
    } catch (error) {
      console.error('Error al cargar Frecuencia:', error);
    }
  }


}

