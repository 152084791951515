<template>
    <div class="flex flex-col md:flex-row">
        <div class="flex-1 md:w-1/2 md:px-6" >
            <h2 class="text-2xl font-bold mb-6 text-center">Ordenes</h2>
            <div class="w-full ">
                <ComLabel :NombreLabel="'Medicamento/Insumo'" />
                <ComInput v-model="NombreMed" :placeholder="'Metacarbamol 750mg Tab.'" />
            </div>

            <div class="relative">
                <ComLabel :NombreLabel="'Via Administracion'" />
                <ComTextAyuda :Text="' Via Oral.'" />
                <ComSelect v-model="via_administracion" :placeholder="'Via Oral'">
                    <option v-for="option in DatosVia" :key="option.id" :value="option.nombre_via">
                        {{ option.nombre_via }}
                    </option>
                </ComSelect>
            </div>

            <div class="w-full ">
                <ComLabel :NombreLabel="'Dosis'" />
                <ComInput v-model="dosis" :placeholder="'Tomar 1 Tableta'" />
            </div>

            <div class="w-full flex">
                <div class="w-[60%] ">
                    <ComLabel :NombreLabel="'Cada'" />
                    <ComInput v-model="frecuencia_medica" :type="'number'"  :placeholder="'8'"/>
                </div>

                <div class="relative w-[50%] -mt-1 ml-5">
                    <ComLabel :NombreLabel="'Frecuencia'" />
                    <ComTextAyuda :Text="' Horas'" />
                    <ComSelect v-model="frecuencia_tiempo">
                        <option v-for="optionvia in Datosfrecuencia" :key="optionvia.id"
                            :value="optionvia.nom_frecuencia">
                            {{ optionvia.nom_frecuencia }}
                        </option>
                    </ComSelect>
                </div>

            </div>

            <div class="w-full ">
                <ComLabel :NombreLabel="'Dias tratamiento'" />
                <ComInput v-model="dias_tratamiento" :type="'number'" :placeholder="'3'" />
            </div>

            <div class="w-full ">
                <ComLabel :NombreLabel="'Cantidad'" />
                <ComInput v-model="cantidad_tratamiento" :type="'number'" :placeholder="'10'" />
            </div>

            <div class="relative w-full mt-2">

                <ComTexarea v-model="observacion" :placeholder="''" :NombreLabel="'Observacion'" />

            </div>


            <BotonPrin :class="buttonClass" :nombre="NomBtnItem" @click="AgregarItmes" />


        </div>
        
        <div class="flex-1 md:w-1/2">


            <div class="w-full">
                <ComTabla>
                    <template #titulos_tabla>
                        <tr>
                            <ComTh :Nombre="'Id'" />
                            <ComTh :Nombre="'Nombre'" />
                            <ComTh :Nombre="'Dosis'" />
                            <ComTh :Nombre="'Total'" />
                            <ComTh :Nombre="'Observacion'" />
                            <ComTh :Nombre="'Editar'" />
                            <ComTh :Nombre="'Eliminar'" />
                        </tr>
                    </template>
                    <template #columna_tabla>
                        <tr class="hover:bg-slate-50" v-for="(item, index) in items" :key="index">
                            <ComTh :Nombre=item.id.toString() />
                            <ComTh :Nombre="item.nombre"/>
                            <ComTh :Nombre="item.nombreOrden"/>
                            <ComTh :Nombre="item.cantidad_tratamiento" />
                            <ComTh :Nombre="item.observacion" class="whitespace-normal" />
                            <ComOpcTable :icono="'edit'" :title="'Editar'" @click="editItem(index)" />
                            <ComOpcTable :icono="'delete'" :title="'Eliminar'" @click="removeItem(index)" />

                        </tr>

                    </template>
                </ComTabla>
                <BotonPrin class="bg-teal-400 hover:bg-teal-600" nombre="Finalizar" @click="enviarOrdenes" />
            </div>
        </div>
    </div>
    {{ RestOrden.idorden }}
</template>
<script setup>
import { ref, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useAuthStore } from '@/store/auth';
import frecuencia from '@/servicios/orden/Frecuencia';
import ViaAdmon from '@/servicios/orden/ViaAdmon';
import BotonPrin from '@/components/botones/BotonPrin.vue';
import ComLabel from '@/components/formulario/ComLabel.vue';
import ComTextAyuda from '@/components/formulario/ComTextAyuda.vue';
import ComInput from '@/components/formulario/ComInput.vue';
import ComTexarea from '@/components/formulario/ComTexarea.vue';
import ComSelect from '@/components/formulario/ComSelect.vue';
import ComTabla from '@/components/Tabla/ComTabla.vue';
import ComTh from '@/components/Tabla/ComTh.vue';
import ComOpcTable from '@/components/Tabla/ComOpcTable.vue';
import Guardarordenart from '@/servicios/orden/GuardarOrdenArt';
import Swal from 'sweetalert2';


const storUser = useAuthStore();
const rutas = useRoute();
const cliente = ref(rutas.query.cliente);
const idadm = ref(rutas.query.idadm);
const idpcte = ref(rutas.query.idpcte);
const idmed = ref(rutas.query.idmed);
const tporden = ref(rutas.query.tporden);
const diasvence = ref(rutas.query.diasvence);
const idhc = ref(rutas.query.idhc);
const listfrecuencia = new frecuencia();
const Datosfrecuencia = ref([]);
const listado_via = new ViaAdmon();
const DatosVia = ref([]);
const NombreMed = ref('');
const via_administracion = ref('');
const dosis = ref('');
const frecuencia_medica = ref('');
const frecuencia_tiempo = ref('');
const dias_tratamiento = ref('');
const cantidad_tratamiento = ref('');
const observacion = ref('');
const items = ref([]);
const Datos_Orden = ref({
    "id_adm_orden_art": idadm,
    "id_pcte_orden_art": idpcte,
    "id_medico_orden_art": idmed,
    "tp_orden": tporden,
    "dias_vence_orden": diasvence,
    'id_hc':idhc,
    "items": items
});
const guardarOrdenArt = new Guardarordenart();
const RestOrden = ref(''); // eslint-disable-next-line
//datos boton editar agregar
const Editar = ref(false);
const NomBtnItem = ref('Agregar');
const buttonClass = ref('bg-cyan-400 hover:bg-cyan-600');

watch(Editar, (newVal) => {
    NomBtnItem.value = newVal ? 'Editar' : 'Agregar';
    buttonClass.value = newVal ? 'bg-blue-500 hover:bg-blue-600' : 'bg-gray-500 hover:bg-gray-600';
});


onMounted(async () => {
    try {

        const resultado = await storUser.login(cliente.value);
        if (resultado === false) {
            console.log('No se pudo obtener el token');
        } else { console.log('conexion exitosa'); }
    } catch (error) { console.error(error) }

    //datos frecuencia select
    try {
        await listfrecuencia.CargaFrecuencia();
        Datosfrecuencia.value = listfrecuencia.GetFrecuencia().value;
    } catch (error) { console.error(error); }
    //datos via administracion
    try {
        await listado_via.CargaViaAdmon();
        DatosVia.value = listado_via.GetViaAdmon().value;
    } catch (error) { console.error(error); }


});

const AgregarItmes = () => {

    if (!NombreMed.value || !via_administracion.value || !dosis.value || !frecuencia_medica.value || !frecuencia_tiempo.value || !dias_tratamiento.value || !cantidad_tratamiento.value) {

        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Faltan datos por Diligenciar',
            toast: true,
        });
    } else {
        items.value.push({
            id: items.value.length + 1,
            nombre: NombreMed.value,
            via: via_administracion.value,
            dosis: dosis.value,
            frecuencia_medica: frecuencia_medica.value,
            frecuencia_tiempo: frecuencia_tiempo.value,
            dias_tratamiento: dias_tratamiento.value,
            cantidad_tratamiento: cantidad_tratamiento.value,
            observacion: observacion.value,
            nombreOrden: `${dosis.value} via ${via_administracion.value} cada ${frecuencia_medica.value} ${frecuencia_tiempo.value} por ${dias_tratamiento.value} dias`,

        });


        NombreMed.value = ''; // Limpiar el campo de entrada
        via_administracion.value = '';
        dosis.value = '';
        frecuencia_medica.value = '';
        frecuencia_tiempo.value = '';
        dias_tratamiento.value = '';
        cantidad_tratamiento.value = '';
        observacion.value = '';

        if (Editar.value) {
            // Lógica para editar
            Swal.fire({
                position: "top-end",
                icon: "info",
                title: "El item ha sido editado exitosamente.",
                showConfirmButton: false,
                timer: 2500,
            });
            Editar.value = false;
        } else {
            // Lógica para agregar
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: "El item ha sido agregado exitosamente.",
                showConfirmButton: false,
                timer: 2500,
            });


        }

    }
};


const editItem = (index) => {
    const item = items.value[index];
    NombreMed.value = item.nombre;
    via_administracion.value = item.via;
    dosis.value = item.dosis;
    frecuencia_medica.value = item.frecuencia_medica;
    frecuencia_tiempo.value = item.frecuencia_tiempo;
    dias_tratamiento.value = item.dias_tratamiento;
    cantidad_tratamiento.value = item.cantidad_tratamiento;
    observacion.value = item.observacion;
    items.value.splice(index, 1);
    Editar.value = true;

};

const removeItem = (index) => {
    Swal.fire({
        title: '¿Estás seguro?',
        text: "No podrás revertir esto!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, eliminarlo!'
    }).then((result) => {
        if (result.isConfirmed) {
            items.value.splice(index, 1);
            // Resetear los IDs de los items
            items.value.forEach((item, idx) => {
                item.id = idx + 1;
            });
            Swal.fire(
                'Eliminado!',
                'El item ha sido eliminado.',
                'success'
            );
        }
    });
};

const enviarOrdenes = async () => {
    if (items.value.length === 0) {
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'No hay items para enviar',
            toast: true,
        });
        return;
    }
    try {
        RestOrden.value = await guardarOrdenArt.GuardarOrdenArt(Datos_Orden);
        console.log('Orden enviada correctamente');
        window.location.href = `https://mi.sysmio.com.co/salud/dev/pdfs/Orden/01Orden.php?idorden=${RestOrden.value.idorden}&idpcte=${idpcte.value}&TpOrden=4`;
    } catch (error) {
        console.error('Error al enviar las ordenes:', error);
    }
};


</script>
