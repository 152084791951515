<template>
    {{ store.showAgenda }}
 <div class="flex justify-center ">
    <BotonPrin :nombre="'Fecha Especifica'" class="mr-2" />
    <BotonPrin :nombre="'Lo antes Posible'" class="bg-gray-500" @click="listado()"/>
 </div>

<div v-if="store.showAgenda==true"  class="grid grid-cols-1 gap-2">
    <ComListAgenda />
</div>

</template>

<script setup>
import ComListAgenda from './ComListAgenda.vue';
import BotonPrin from './botones/BotonPrin.vue';
import { Citas } from '@/store/StoreCitas';
const store= Citas();

function listado() {
    store.ShowAgenda(true);
}


</script>