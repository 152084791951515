
import { ref } from 'vue';
import { useAuthStore } from '@/store/auth';
import Swal from 'sweetalert2';


export default class Guardarordenart {
    constructor() {

        this. store = useAuthStore();
        this. resultado = ref('');
      }

      async GuardarOrdenArt(items) {
        try {
          const url = this.store.baseurl + 'ordenes/guardar';
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + this.store.token
            },
            body: JSON.stringify(items.value)
          });

          if (!response.ok) {
            throw new Error(response);
          }

          this.resultado.value = await response.json();
          
          Swal.fire({
            icon: 'success',
            title: 'Ordenes enviadas',
            text: 'Los datos se han enviado correctamente',
            toast: true,
          });

          

        } catch (error) {
          console.error('Error al enviar las ordenes:', error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Adminresultado',
            toast: true,
          });
        }
        return this.resultado.value;
      }
}


