
import { ref } from 'vue';
import { useAuthStore } from '@/store/auth';


export default class ServicioCitas {
    constructor() {

        this. store = useAuthStore();
        this. citas = ref('');
      }

  getcitas() {
    return this.citas;
  }

  async cargarCitas() {
    try {
      const url =this.store.baseurl+'agenda';
      const resultado = await fetch(url, {
        method: 'GET',
        headers: {
           'Content-Type':'application/json',
            'Accept':'application/json',
            'Authorization': 'Bearer '+this.store.token
        }
      });
      const datos = await resultado.json();
      this.citas.value = datos;
    } catch (error) {
      console.error('Error al cargar citas:', error);
    }
  }


}

