 /* eslint-disable */
import { ref } from 'vue';
import { useAuthStore } from '@/store/auth';
import { Citas } from '@/store/StoreCitas';


export default class ServicioClaseCita {
    constructor() {

        this. store = useAuthStore();
        this.storeClaseCita = Citas();
        this. ClaseCita = ref([]);
      }

  GetClaseCitas() {
    return this.ClaseCita;
  }

  async CargaClaseCitas() {
    try {
      const url =this.store.baseurl+'agenda/'+this.storeClaseCita.idClaseCita+'/clasecita';
      const resultado = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer '+this.store.token
        }
      });
      const datos = await resultado.json();
      this.ClaseCita.value = datos;
    } catch (error) {
      console.error('Error al cargar citas:', error);
    }
  };

  


}

