<template>
    <div>
        <h1 class="text-center text-red-500 mt-5" >vista index cargada correctamente</h1>
    </div>
</template>
<script setup>
import { ref,onMounted} from 'vue';
import { useRoute } from 'vue-router';
import router from '@/router';
import{ useAuthStore} from '../store/auth';

const rutas = useRoute();
const valor = ref(rutas.params.usuario);
const store = useAuthStore();
onMounted(async () => {
  try {
    const resultado = await store.login(valor.value); 
    if (resultado === false) {
      console.log('No se pudo obtener el token');
    } else {
        router.push({ name: 'citas' });
    }
  } catch (error) {
    console.error( error);
  }
});
</script>