import {defineStore} from 'pinia'
/* eslint-disable */
export const Citas = defineStore('StoreCita',{
    state: () => {
        return {
            idClaseCita:0,
            idcita:0,
            showAgenda:false,
        }
    },
    actions: {
        StoreClaseCita(idClaseCita){
            this.idClaseCita=idClaseCita
        },

        StoreCita(idcita){
            this.idcita=idcita
        },
        ShowAgenda(idshowAgenda){
            this.showAgenda=idshowAgenda
        },
        
    }

    })