<template>
{{ store.idcita }}
 <div class="flex flex-col  overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
      <div class="overflow-hidden">
        <table
          class="min-w-full text-left text-sm font-light text-surface dark:text-white">
          <thead
            class="border-b border-neutral-200 font-medium dark:border-white/10">
            <tr>
              <th scope="col" class="px-6 py-4">id</th>
              <th scope="col" class="px-6 py-4">Hora</th>
              <th scope="col" class="px-6 py-4">Consultorio</th>
              <th scope="col" class="px-6 py-4">Doctor_nombre</th>
              <th scope="col" class="px-6 py-4">Doctor_apellido</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="DetDatos in datosDetCitas" :key="DetDatos.id_det_agenda" class="border-b border-neutral-200 dark:border-white/10">
              <td class="whitespace-nowrap px-6 py-4 font-medium">{{ DetDatos.id_det_agenda }}</td>
              <td class="whitespace-nowrap px-6 py-4">{{ formatearHora(DetDatos.fecha_det_agenda) }}</td>
              <td class="whitespace-nowrap px-6 py-4">{{ DetDatos.nom_cons }}</td>
              <td class="whitespace-nowrap px-6 py-4">{{ DetDatos.nom_pers }}</td>
              <td class="whitespace-nowrap px-6 py-4">{{ DetDatos.apell_pers }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</template>

<script setup>
import { ref,onMounted} from 'vue';
import ServDetCita from '@/servicios/ServicioDetCita';
import { Citas } from '@/store/StoreCitas';

const store= Citas();
const ServDetCitas = new ServDetCita();
const datosDetCitas = ref([]);

const formatearHora = (fecha) => {
  const date = new Date(fecha);
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};
onMounted(async () => {
  await ServDetCitas.CargaDetCitas();
  datosDetCitas.value = ServDetCitas.GetDetCitas().value;
});

</script>