<template>

    <div class="container">
          <h1 class="flex justify-center text-lg font-serif mt-2">Especialidad
            <p class="text-lg  font-serif text-red-500 ml-1" 
             v-if="especialidades.length==1">{{ especialidades[0].nom_esp }}</p></h1>
            
             <div v-if="especialidades.length !==1" class="flex justify-center">
                    <div class="ml-2" v-for="especialidad in especialidades" :key="especialidad.id_esp">
                        <BotonPrin :nombre="especialidad.nom_esp" class="bg-cyan-400" @click="IdClaseCita(especialidad.id_esp)"  />
                    </div>
             </div>
             <ComClaseCita />

    </div>
  </template>
  
  <script setup>
  import ServicioCitas from '@/servicios/ServicioCita';
  import {ref,onMounted } from 'vue';
  import BotonPrin from '@/components/botones/BotonPrin.vue';
  import ComClaseCita from '@/components/ComClaseCita.vue';
  import{ useAuthStore} from '../store/auth';
  import { useRoute } from 'vue-router';
  import { Citas } from '@/store/StoreCitas';

  const rutas = useRoute();
  const cliente = ref(rutas.params.cliente);
  const storUser = useAuthStore();
  
  const servicioCitas = new ServicioCitas();
  const especialidades = ref([]);
  const store= Citas();

  onMounted(async () => {
  try {
    
    const resultado = await storUser.login( cliente.value); 
    if (resultado === false) {
      console.log('No se pudo obtener el token');
    }else{
      console.log('conexion exitosa');
    }
  } catch (error) {
    console.error( error);
  }

   
    await servicioCitas.cargarCitas();
    especialidades.value = servicioCitas.getcitas().value;
   
    if(especialidades.value.length==1){
        store.StoreClaseCita(especialidades.value[0].id_esp);
      }
});



  function IdClaseCita(id){
    
    store.StoreClaseCita(id);
  }


  </script>