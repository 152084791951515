
import { ref } from 'vue';
import { useAuthStore } from '@/store/auth';


export default class Frecuencia {
    constructor() {

        this. store = useAuthStore();
        this. Frecuencia = ref('');
      }

  GetFrecuencia() {
    return this.Frecuencia;
  }

  async CargaFrecuencia() {
    try {
      const url =this.store.baseurl+'servicios/frecuencia';
      const resultado = await fetch(url, {
        method: 'GET',
        headers: {
           'Content-Type':'application/json',
            'Accept':'application/json',
            'Authorization': 'Bearer '+this.store.token
        }
      });
      const datos = await resultado.json();
      this.Frecuencia.value = datos;
    } catch (error) {
      console.error('Error al cargar Frecuencia:', error);
    }
  }


}

