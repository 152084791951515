 /* eslint-disable */
import { ref } from 'vue';
import { useAuthStore } from '@/store/auth';
import { Citas } from '@/store/StoreCitas';


export default class ServDetCita {
    constructor() {

        this. store = useAuthStore();
        this.storeDetCita = Citas();
        this. DetCitas = ref([]);
      }

  GetDetCitas() {
    return this.DetCitas;
  }

  async CargaDetCitas() {
    try {
      const url =this.store.baseurl+'agenda/'+this.storeDetCita.idcita+'/det_agenda';
      const resultado = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer '+this.store.token
        }
      });
      const datos = await resultado.json();
      this.DetCitas.value = datos;
      console.log(this.DetCitas.value);
    } catch (error) {
      console.error('Error al cargar citas:', error);
    }
  }

 


}

